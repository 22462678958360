const trackGa = (trackingObj) => {
	const isBrowser = typeof window !== 'undefined';
	if (isBrowser) {
		const DDC = window.DDC || {};
		const defaultTrackingObj = {
			event: 'widgetUserEvent',
			widgetName: 'ws-ddc-logo',
			widgetId: '',
			element: '',
			action: 'clicked',
			result: ''
		};

		if (DDC.fireTrackingEvent) {
			DDC.fireTrackingEvent({
				...defaultTrackingObj,
				...trackingObj
			});
		}
	}
};
const trackEvent = (name, windowId) => {
	trackGa({
		element: `${name} logo`,
		result: `Navigated to ${name}`,
		widgetId: windowId
	});
};

export default trackEvent;
