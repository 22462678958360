import { SET_WIDGET_DATA } from '../actions/types';

const widgetData = (state = {}, { type, payload }) => {
	switch (type) {
		case SET_WIDGET_DATA:
			return payload || state;
		default:
			return state;
	}
};

export default widgetData;
