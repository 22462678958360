import React from 'react';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import trackEvent from '../utils/trackEvent';

const AdChoices = () => {
	const labels = useLabels();
	const { windowId } = useRequestData();
	const { adChoicesClasses, showAdChoices } = usePrefs();

	if (showAdChoices === 'false') return null;

	return (
		<a
			href="https://www.dealer.com/opt-out/?utm_campaign=ddc_referral"
			target="_blank"
			rel="noopener noreferrer"
			className={setClassNames([
				'adchoices',
				'text-black',
				'line-height-reset',
				'd-flex',
				'align-items-center',
				...adChoicesClasses.split(' ')
			])}
			onClick={() => {
				trackEvent('Ad Choices', windowId);
			}}
		>
			{labels.get('ADCHOICES')}
			<img
				className="d-inline"
				width="11"
				height="11"
				aria-hidden
				alt=""
				src="https://images.dealer.com/ddc/adchoices/logo.svg"
			/>
		</a>
	);
};

export default AdChoices;
