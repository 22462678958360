import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import trackEvent from '../utils/trackEvent';

const DDCLogo = ({ color }) => {
	const labels = useLabels();
	const { windowId } = useRequestData();
	const { ddcLogoClasses, showWebsiteByText } = usePrefs();

	return (
		<a
			href="https://www.dealer.com/"
			target="_blank"
			rel="noopener noreferrer"
			onClick={() => {
				trackEvent('Dealer.com', windowId);
			}}
			className={setClassNames([
				'ddc-logo-link',
				'text-center',
				`text-${color}`,
				'text-decoration-none',
				'ddc-font-size-xsmall',
				...ddcLogoClasses.split(' ')
			])}
		>
			{showWebsiteByText === 'true' && labels.get('WEBSITE_BY')}
			<img
				src={`/static/assets/images/logos/dealer-dot-com/logo-one-color-${color}.svg`}
				alt={labels.get('DEALER_DOT_COM')}
				height="20"
				width="162"
			/>
		</a>
	);
};

DDCLogo.propTypes = {
	color: PropTypes.string.isRequired
};

export default DDCLogo;
