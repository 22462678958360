const getFinalLogoColor = (ddcLogoColor, ddcLogoThemekitOverride, themekit) => {
	const themekitsToOverride = ddcLogoThemekitOverride
		.split(',')
		.map((themekitId) => themekitId.trim())
		.filter(Boolean);

	if (themekitsToOverride.includes(themekit)) {
		if (ddcLogoColor === 'white') {
			return 'black';
		}
		if (ddcLogoColor === 'black') {
			return 'white';
		}
	}
	return ddcLogoColor;
};

export default getFinalLogoColor;
